import { RESTART_IMG, START_IMG } from "../assets/images";
import { LABELS_FOR_EVENT, SPLIT_TIMES_LABELS, tracks } from "../constants";
import { EEventTypes, TSplitTimesLabels } from "../pages/RaceEvents/types";

export const getSplitTimesType = (splitTimeKey: keyof TSplitTimesLabels): string => {
  const what = SPLIT_TIMES_LABELS[splitTimeKey];
  return what;
};

export const trimKmTime = (value: string) => {
  if (value.startsWith("1.")) {
    return value.substring(2);
  }
  return value;
}

export const getEventLabelAndStyles = (eventType: EEventTypes | 'Pagar', timerStop: number, paceName: string, prelKmTime: object) => {
  switch (eventType) {
    case EEventTypes.TimeToStart:
      if (timerStop !== -1) {
        return {
          eventLabel: LABELS_FOR_EVENT.PAGAR,
          timeStyle: "event-type-time-pagar",
          cardStyle: "inner-card-pagar",
          raceInfoTypeStyle: "race-info-type-pagar",
        };
      }
      return {
        eventLabel: LABELS_FOR_EVENT.TIME_TO_START,
        timeStyle: "event-type-time-start",
      };

    case 'Pagar': // New case for the "PAGAR" event
      return {
        eventLabel: LABELS_FOR_EVENT.PAGAR,
        timeStyle: "event-type-time-pagar",
        cardStyle: "inner-card-pagar",
        raceInfoTypeStyle: "race-info-type-pagar",
      };

    case EEventTypes.TimeToDef:
      return {
        eventLabel: LABELS_FOR_EVENT.TIME_TO_DEF,
        timeStyle: "event-type-time-def",
      };

    case EEventTypes.RaceStarted:
      return {
        eventLabel: LABELS_FOR_EVENT.RACE_STARTED,
        timeStyle: "event-type-start",
      };

    case EEventTypes.RaceFinished:
      return {
        eventLabel: LABELS_FOR_EVENT.RACE_FINISHED,
        timeStyle: prelKmTime ? "event-type-finish" : "event-type-finish-img",
      };

    case EEventTypes.SplitTimes:
      return {
        eventLabel: getSplitTimesType(paceName as unknown as keyof TSplitTimesLabels),
        timeStyle: "event-type-split-time",
      };

    case EEventTypes.RaceRestarted:
      return {
        eventLabel: LABELS_FOR_EVENT.RACE_RESTARTED,
        timeStyle: "event-type-restart",
        cardStyle: "inner-card-pagar",
      };

    default:
      return {};
  }
};



export const getImgSrc = (eventType: EEventTypes): string | null => {
  const imgMap: Record<EEventTypes, string | null> = {
    [EEventTypes.TimeToStart]: null,
    [EEventTypes.TimeToDef]: null,
    [EEventTypes.RaceStarted]: START_IMG,
    [EEventTypes.RaceFinished]: null,
    [EEventTypes.SplitTimes]: null,
    [EEventTypes.RaceRestarted]: RESTART_IMG,
    [EEventTypes.Pagar]: null
  };
  return imgMap[eventType];
};

export const getTrackName = (trackId: number) => {
  const trackDetail = tracks.find((item) => item.sportId === trackId);
  return trackDetail?.name;
};